import React, { Component } from "react";
import { slide as Menu } from 'react-burger-menu'
import BurgerButton from './BurgerButton';

class Burger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
    }

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    }
    showSettings(event) {
        event.preventDefault();
    }

    render() {
        var styles = {
            bmBurgerButton: {
                position: 'fixed',
                width: '36px',
                height: '30px',
                left: '36px',
                top: '36px'
            },
            bmBurgerBars: {
                background: '#373a47'
            },
            bmBurgerBarsHover: {
                background: '#a90000'
            },
            bmCrossButton: {
                height: '0px',
                width: '0px'
            },
            bmMenuWrap: {
                height: '100%'
            },
            bmMenu: {
                background: '#808080',
                fontSize: '1.15em'
            },
            bmMorphShape: {
                fill: '#373a47'
            },
            bmItemList: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '10 0em',
                color: '#b8b7ad',
            },
            bmItem: {
                display: 'inline-block'
            },
            bmOverlay: {
                background: 'rgba(0, 0, 0, 0.3)'
            }
        }
        return (
            <Menu pageWrapId={"page-wrap"} disableAutoFocus styles={styles} customCrossIcon={false}>
                <BurgerButton title="Home" to=''> Home </BurgerButton>
                <BurgerButton title="Resume" to='resume'> Resume </BurgerButton>
               
            </Menu>
        );
    }
}
export default Burger;
