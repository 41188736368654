import React, { Component } from "react";
import $ from "jquery";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import "./App.scss";
import HomePage from "./components/HomePage";
import Resume from "./components/Resume";

class App extends Component {

    constructor(props) {
        super();
        this.state = {
            foo: "bar",
            resumeData: {},
            sharedData: {},
        };
    }
    render() {
        return (
            <Router>
                <div>
                <Routes>
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/resume" element={<Resume />} />
                    </Routes>
                    </div>
            </Router>

        );
    }
}

export default App;
