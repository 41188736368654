import React, { Component } from 'react';
import Burger from './Burger'
class Resume extends Component {

    render() {
        const PDF_STYLE = {
            width: '80%',
            height: '70vh',
            border: 'none',
        };
        return (
            <div>
                <Burger />
                <header id="home" style={{ height: '100vh', display: 'block' }}>
                    <div className="col-md-12">
                        <div>
                            <br />
                            <h1 className="mb-0" style={{ paddingBottom: '60px' }}> Resume
                            </h1>
                            <div className="title-container">
                                <iframe
                                    title="Resume PDF Viewer"
                                    src="images/portfolio/Resume.pdf"
                                    style={PDF_STYLE}
                                />
                            </div>
                        </div>
                    </div>
                </header>
            </div>

        );
    }
}
export default Resume;
