import React, { Component } from "react";
import $ from "jquery";
import "../App.scss";
import Burger from "./Burger";
import Header from "./Header";
import Footer from "./Footer";
import About from "./About";
import Experience from "./Experience";
import Projects from "./Projects";
import Skills from "./Skills";

class HomePage extends Component {

    constructor(props) {
        super();
        this.state = {
            foo: "bar",
            resumeData: {},
            sharedData: {},
        };
    }

    applyPickedLanguage(pickedLanguage, oppositeLangIconId) {
        this.swapCurrentlyActiveLanguage(oppositeLangIconId);
        document.documentElement.lang = pickedLanguage;
        var resumePath =
            document.documentElement.lang === window.$primaryLanguage
                ? `res_primaryLanguage.json`
                : `res_secondaryLanguage.json`;
        this.loadResumeFromPath(resumePath);
    }

    swapCurrentlyActiveLanguage(oppositeLangIconId) {
    }

    componentDidMount() {
        this.loadSharedData();
        this.applyPickedLanguage(
            window.$primaryLanguage,
            window.$secondaryLanguageIconId
        );
    }

    loadResumeFromPath(path) {
        $.ajax({
            url: path,
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ resumeData: data });
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }

    loadSharedData() {
        $.ajax({
            url: `portfolio_shared_data.json`,
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ sharedData: data });
                document.title = `${this.state.sharedData.basic_info.name}`;
            }.bind(this),
            error: function (xhr, status, err) {
                console.log(xhr.status, xhr.statusText);
                alert(err);
            },
        });
    }

    render() {
        return (
            <div>
                <Burger />
                <Header sharedData={this.state.sharedData.basic_info} />
                <About
                    resumeBasicInfo={this.state.resumeData.basic_info}
                    sharedBasicInfo={this.state.sharedData.basic_info}
                />
                <Projects
                    resumeProjects={this.state.resumeData.projects}
                    resumeBasicInfo={this.state.resumeData.basic_info}
                />
                <Skills
                    sharedSkills={this.state.sharedData.skills}
                    resumeBasicInfo={this.state.resumeData.basic_info}
                />
                <Experience
                    resumeExperience={this.state.resumeData.experience}
                    resumeBasicInfo={this.state.resumeData.basic_info}
                />
                <Footer sharedBasicInfo={this.state.sharedData.basic_info} />
            </div>
        );
    }
}

export default HomePage;
