import React, { Component } from 'react';

class BurgerButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };        
    }

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    }

    handleClick = () => {
        // Handle navigation logic here
        window.location.href = '/' + this.props.to
    };

    render() {
        const { to } = this.props;

        const buttonStyle = {
            height: '50px',
            width: '80%',
            marginTop: '30px',
            position: 'relative',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            color: '#000000',
            outline: 'none',
            background: this.state.isHovered ? '#D3D3D3' : '#808080', // Change background color on hover
            transition: 'background-color 0.3s ease',
            alignItems: 'left'
        };

        return (
            <button
                style={buttonStyle}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
            >
                {this.props.children}
            </button>
        );
    }
}
export default BurgerButton;
